import React from "react";
import { Helmet } from "react-helmet";
import PromoDubSmall from "./PromoDubSmall";
import PromoGrid from "./PromoGrid";

const HomePage = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home | Dotter and Pop</title>
        <meta property="og:title" content="Home | Dotter and Pop" />
        <meta property="description" content="Technology Consulting and Services." />
      </Helmet>

      <div className="flex justify-center items-center bg-green-300">
        <h1 className="text-5xl uppercase font-black">Dotter+Pop</h1>
      </div>

      <div className="relative overflow-hidden bg-white py-8">
        <div className="relative py-2 px-6 lg:px-8">
          <div className="mx-auto max-w-prose text-lg">
            <h2>
              <span className="mt-2 block text-center
               text-2xl font-bold leading-8 tracking-tight 
               text-gray-900 sm:text-3xl">
                Our Services
              </span>
            </h2>
          </div>
        </div>
      </div>

      <PromoGrid />

    </div>
  );
};

export default HomePage;
