import React from "react";
import { Helmet } from "react-helmet";
import PromoDub from "./PromoDub";

const ProductPage = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Portfolio | Dotter+Pop</title>
        <meta property="og:title" content="Portfolio | Dotter+Pop" />
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <div className="flex justify-center items-center bg-red-100">
        <h1 className="text-5xl uppercase font-black">Portfolio</h1>
      </div>

    This section will be refreshed soon! 

    
    </div>
  );
};

export default ProductPage;
