import React from "react";
import { Helmet } from "react-helmet";

const AboutPage = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About | Dotter + Pop</title>
        <meta property="og:title" content="About | Dotter and Pop" />
      </Helmet>

      <div className="flex justify-center items-center bg-yellow-300">
        <h1 className="text-5xl uppercase font-black">About</h1>
      </div>

      <div className="relative overflow-hidden bg-white py-8">
        <div className="relative py-2 px-6 lg:px-8">
          <div className="mx-auto max-w-prose text-lg">
            <h2>
              <span className="mt-2 block text-center
               text-2xl font-bold leading-8 tracking-tight 
               text-gray-900 sm:text-3xl">
                Our Story
              </span>
            </h2>
            <p className="mt-8 text-xl leading-8 text-gray-500">
              Dotter + Pop is inspired by families. Everyone is the child of
              someone. Families are universal. If are all family, we respect
              family members, and we take care of eachother.
            </p>
          </div>
        </div>
      </div>



      <div className="relative overflow-hidden bg-white py-1">
        <div className="relative px-6 lg:px-8">
          
          <div className="prose prose-lg prose-indigo mx-auto mt-6 text-gray-500">
            <p>
              Eric Hestenes is a technology industry veteran with experience in
              financial services aka FinTech, non-profit, ed-tech, open source, 
              and artifical intelligence. He recently served as Director of Engineering for Paramount and managed a global team of developers supporting the https://noggin.com website.  
              He also served as Director of Engineering for
              the George Lucas Educational Foundation. As part of this, he lead
              product development for the website Edutopia.org. Prior to this he
              worked as a open source technology consultant to diverse
              organizations including New York Law School, 
              the White House Office of Science and Technology Policy, 
              the United States Department of Treasury, 
              the Linux Foundation, 
              the Stupski Foundation,
              Stanford University Libraries, 
              and more.
              Eric also served as Vice President of Technology 
              and Vice President of Brokerage Operations at Charles Schwab. 
            </p>
            <blockquote>
              <p>A non-toxic technology company.</p>
            </blockquote>
            <p>
              That's our tagline. 
              This is of course an aspiration and also a reflection on
              the state of our technology industry. We aspire to be non-toxic,
              to be kind, to be inclusive, to be non-discriminatory. 
            </p>
           

            <h1>
              <span
                className="pt-4 mt-2 block text-center text-3xl font-bold
               leading-8 tracking-tight text-gray-900 sm:text-4xl"
              >
                Contact
              </span>
              </h1>
              <p className="text-center">
                Email address: info @ dotterpop . com
              </p>
              <p className="text-center">
                Based in the San Francisco Bay Area.
              </p>
              <p></p>
          </div>
        </div>
      </div>

     

      <div class="flex flex-wrap justify-center">
        <div class="max-w-sm rounded overflow-hidden shadow-lg">
          <img
            class="p-1 bg-white border rounded max-w-sm"
            alt="an otter swimming on back drinking a can of soda."
            src={require("../assets/otter_lowres.jpg")}
          />

          <div class="px-6 py-4">
            <div class="font-bold text-xl mb-2">Our Mascot</div>
            <p class="text-gray-700 text-base">p.s... We love sea otters!</p>
          </div>

          <div class="px-6 pt-4 pb-2">
            <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
              #otter
            </span>
            <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
              #pop
            </span>
            <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
              #livinthebestlife
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
