import React from "react";
import { useNavigate } from "react-router-dom";

import { trackAuth } from "../services/trackAuth";

import { Helmet } from "react-helmet";

const ProtectedPage = ({ x }) => {
  const navigate = useNavigate();
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dashboard | Dotter+Pop</title>
        <meta property="og:title" content="Dashboard | Dotter+Pop" />
      </Helmet>

      <div className="flex justify-center items-center bg-cyan-300">
        <h1 className="text-5xl uppercase font-black">Dashboard</h1>
      </div>

      <div className="m-4 ">
        <p>Welcome! You are logged in. </p>
        <br/>
        <button
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-4 rounded focus:outline-none focus:shadow-outline"
          onClick={() => {
            trackAuth.logout(() =>
              navigate("/login", {
                state: { from: { pathname: "/protected" } },
              })
            );
          }}
        >
          Sign Out
        </button>
      </div>
    </div>
  );
};

export default ProtectedPage;
