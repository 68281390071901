import React from "react";

export default function PromoGrid() {
  return (
    <div className="bg-white">
      <div className="w-full ">
        <div className="grid grid-cols-2 gap-8 lg:py-0 lg:px-8 py-0 px-6">
    
    
          <div className="text-center">
            <h2 className="text-gray-900 font-bold tracking-tight">
              Software Engineering Management
            </h2>
            <p className="tracking-tight">
              Manage software and systems development and extended project teams. People management
              and staff coaching. Client relationship management, vendor
              partner management.
            </p>
          </div>

          <div className="text-center">
            <h2 className="text-gray-900 font-bold tracking-tight">
              Finding the Best Solution
            </h2>
            <p className="tracking-tight">
              Finding an appropriate solution approach for the specific needs of
              a project. Technical needs assessment. Seeking the best approach
              considering budget and skillsets.
            </p>
          </div>

 
          <div className="text-center">
            <h2 className="text-gray-900 font-bold tracking-tight">
              Data Management
            </h2>
            Data extract, transform, load using modern tools.  
              Experience with managing engineers working on data marts and data warehouses. 
              Experience with modern tools including Postgre SQL, MariaDB, Snowflake, Tableau, S3, Athena, Glue.  
               AI strategy. 
          </div>


              
          <div className="text-center">
            <h2 className="text-gray-900 font-bold tracking-tight">
              Strategic Planning
            </h2>
            Collaborate with your team to define the best process for planning.
            Conduct gap analysis or technical audit. Integrate technical and business strategy.
            User audience research.
          </div>

      
                             <div className="text-center">
            <h2 className="text-gray-900 font-bold tracking-tight">
              Product Development
            </h2>
            <p className="tracking-tight">
              Agile project management, scrum, sprint planning, issue tracking, and retrospectives. 
              Cloud native development, CI/CD pipeline, troubleshooting to keep project moving.
            </p>
          </div>

          <div className="text-center">
            <h2 className="text-gray-900 font-bold tracking-tight">
              Platform Integrations
            </h2>
            <p className="tracking-tight">
              Search Engine Optimization.  
              Improve search results on Google using code adjustments and SEO tools.  
              Develop social media solutions including API level integrations and
              audience development using social media tools. Mastodon configuration. 
            </p>
          </div>

   <div className="text-center">
            <h2 className="text-gray-900 font-bold tracking-tight">
              Interests 
            </h2>
            <p className="tracking-tight">
             Programming Languages: Javascript, React, Python, Swift
            </p>
            <p className="tracking-tight">
Machine Learning Frameworks: ChatGPT, Claude, TensorFlow, PyTorch, scikit-learn
            </p>
            <p className="tracking-tight">
Natural Language Processing: NLTK, spaCy, Gensim
            </p>

                </div>


                
          
        </div>
      </div>
    </div>
  );
}
